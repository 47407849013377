import {serve} from "./constant";

const Mock = require('mockjs');
const Random = Mock.Random;

const reg = function (uri) {
  let url = process.env.VUE_APP_DOMAIN + uri;
  return RegExp(url + '.*');
};

const respack = function (data) {
  return {
    code: 200,
    data,
    msg: "A"
  }
};


Mock.mock(reg(serve.api.swipe), 'get', () => {
  let images = [];
  for (let i = 0; i < Mock.Random.integer(2, 7); i++) {
    images[i] = Mock.Random.image();
  }
  return respack({images})
});

Mock.mock(reg(serve.api.payouts), 'get', () => {
  let payout = [];
  for (let i = 0; i < Mock.Random.integer(1, 10); i++) {
    payout[i] = Mock.mock({
      bet: "KSH" + Random.integer(1, 1000),
      num: [Random.integer(1, 9),Random.integer(1, 9),Random.integer(1, 9)].join(","),
      win: [Random.integer(1, 9),Random.integer(1, 9),Random.integer(1, 9)].join(","),
      odds: Random.integer(0, 100),
      multiple: Random.integer(0, 1000),
      wouldWin: "KSH" + Random.integer(0, 1000000).toLocaleString()
    })
  }
  return respack({payout})
});

Mock.mock(reg(serve.api.orders), 'get', () => {
  let orders = [];
  for (let i = 0; i < Mock.Random.integer(1, 5); i++) {
    orders[i] = Mock.mock({
      date: "21-01-01\n19:00",
      num: [Random.integer(1, 9),Random.integer(1, 9),Random.integer(1, 9)].join(","),
      win: [Random.integer(1, 9),Random.integer(1, 9),Random.integer(1, 9)].join(","),
      bet: "KSH" + Random.integer(1, 1000),
      result: Random.integer(0, 100),
      winnings: "KSH" + Random.integer(0, 10000).toLocaleString()
    })
  }
  return respack({orders})
});

Mock.mock(reg(serve.api.results), 'get', () => {
  let results = [];
  for (let i = 0; i < Mock.Random.integer(3, 7); i++) {
    results[i] = Mock.mock({
      id: Random.integer(100000, 999999),
      time: "01/01/2020 08:30",
      num: Random.integer(100, 999),
    })
  }
  return respack({results})
});

Mock.mock(reg(serve.api.winners), 'get', () => {
  let winners = [];
  for (let i = 0; i < Mock.Random.integer(3, 7); i++) {
    winners[i] = Mock.mock({
      phoneNumber: 0,
      ticketId: 0,
      picks: 0,
      stake: 0,
      winnings: 0
    })
  }
  return respack({winners})
});

Mock.mock(reg(serve.api.bet), 'get', () => {
  let payment = Mock.mock({
    quickDraw: Random.character(),
    drawData: "B",
    stake: "C",
    gift: "D",
    totalPayment: "E"
  });
  return respack({payment})
});

Mock.mock(reg(serve.api.pay), 'get', () => {
  let pay = Mock.mock({
    r: Random.boolean()
  });
  return respack({pay})
});
