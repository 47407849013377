import Vue from 'vue'
import {
  Button,
  Collapse,
  CollapseItem, DatetimePicker,
  DropdownItem,
  DropdownMenu,
  Field,
  Image,
  Lazyload,
  NumberKeyboard,
  Popup,
  Swipe,
  SwipeItem,
  Tab,
  Tabs,
  Toast
} from 'vant';
import 'vant/lib/swipe/style'
import 'vant/lib/swipe-item/style'
import 'vant/lib/tabs/style'
import 'vant/lib/tab/style'
import 'vant/lib/image/style'
import 'vant/lib/field/style'
import 'vant/lib/button/style'
import 'vant/lib/dropdown-menu/style'
import 'vant/lib/dropdown-item/style'
import 'vant/lib/popup/style'
import 'vant/lib/toast/style'
import 'vant/lib/number-keyboard/style'
import 'vant/lib/collapse/style'
import 'vant/lib/collapse-item/style'
import 'vant/lib/datetime-picker/style'

Vue.use(Swipe);
Vue.use(SwipeItem);
Vue.use(Lazyload);
Vue.use(Tabs);
Vue.use(Tab);
Vue.use(Image);
Vue.use(Field);
Vue.use(Button);
Vue.use(DropdownMenu);
Vue.use(DropdownItem);
Vue.use(Popup);
Vue.use(Toast);
Vue.use(NumberKeyboard);
Vue.use(Collapse);
Vue.use(CollapseItem);
Vue.use(DatetimePicker);
