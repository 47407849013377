export function TransData() {
  if (typeof TransData.instance === "object") {
    return TransData.instance;
  }
  TransData.instance = this;
  return this;
}

export function checkInputStr() {
  let count = 0;
  for (let i in arguments) {
    let value = arguments[i];
    if (value != null && typeof (value) === "string") {
      if (value.trim() !== "") count++;
    }
  }
  return count === arguments.length;
}

export function getQueryString(name) {
  let reg = new RegExp("(^|&)" + name + "=([^&]*)(&|$)", "i");
  let r = window.location.search.substr(1).match(reg);
  if (r != null) {
    return decodeURIComponent(r[2]);
  }
  return null;
}

export function openLinkNew(url) {
  let a = document.createElement("a");
  a.setAttribute("href", url);
  a.setAttribute("target", "_blank");
  document.body.appendChild(a);
  a.click();
  document.body.removeChild(a);
}
