import Vue from 'vue'
import Vuex from "vuex";
import {Toast} from "vant";
import {CookiesKey, serve} from "./constant";
import {TransData} from "./utils/misc";

Vue.use(Vuex);

function updateArray(target, data) {
  target.splice(0, target.length);
  data.forEach((item, index) => {
    Vue.set(target, index, item);
  })
}

const store = new Vuex.Store({
  state: {
    results: [],
    winners: [],

    paymentProcessing: {},

    token: null,
    serve_res: {},
    openSec: 0,
    closeSec: 0,
    drawId: 0,

    bet_msg: 'Operation failed, please try again later.',
    bet_result: false,
    bet_score: 0,
    balance: 0,

    banners1: [],
    banners2: [],
  },
  getters: {
    results: state => {
      return state.results;
    },
    winners: state => {
      return state.winners;
    },
    paymentProcessing: state => {
      return state.paymentProcessing;
    },
    token: (state) => {
      return state.token;
    },
    serve_res: (state) => (uri) => {
      return state.serve_res[uri];
    },
    openSec: state => {
      return state.openSec ?? 0;
    },
    closeSec: state => {
      return state.closeSec ?? 0;
    },
    drawId: state => {
      return state.drawId ?? 0;
    },
    banners1: state => {
      return state.banners1;
    },
    banners2: state => {
      return state.banners2;
    },
    bet_msg: state => {
      return state.bet_msg;
    },
    bet_result: state => {
      return state.bet_result;
    },
    bet_score: state => {
      return state.bet_score;
    },
    balance: state => {
      return state.balance;
    }
  },
  mutations: {
    results(state, data) {
      data.forEach((item, index) => {
        Vue.set(state.results, index, item);
      })
    },
    winners(state, data) {
      data.forEach((item, index) => {
        Vue.set(state.winners, index, item);
      })
    },
    paymentProcessing(state, data) {
      state.paymentProcessing.quickDraw = data.quickDraw;
      state.paymentProcessing.drawData = data.drawData;
      state.paymentProcessing.stake = data.stake;
      state.paymentProcessing.gift = data.gift;
      state.paymentProcessing.totalPayment = data.totalPayment;
      console.log(state.paymentProcessing);
    },
    bet_result(state, data) {
      state.bet_result = data;
    },
    bet_msg(state, data) {
      state.bet_msg = data;
    },
  },
  actions: {
    serve_req({state}, config) {
      return Vue.axios.request(config).then((value) => {
        let data = {};
        switch (value.status) {
          case 200:
            if (value.data.code !== null) {
              switch (value.data.code) {
                case 200:
                  data = value.data.data;
                  break;
                default:
                  //console.log(value.data.message)
                  //Toast.fail(value.data.message);
                  if(config.that){
                    //console.log(config.that)
                    config.that.$store.commit("bet_result", false);
                    config.that.$store.commit("bet_msg", value.data.message);
                    config.that.popupBetFailed = true;
                  }
                  //return Promise.reject(new Error(value.data.message));
              }
            }
            break;
          case 401:
            break;
          default:
            Toast.fail("网络错误");
            return;
        }
        state.serve_res[config.url] = data;
        switch (config.url) {
          case serve.user.login:
            state.token = data;
            new TransData()[CookiesKey.token] = data;
            sessionStorage.setItem("token",data)
            break;
          case serve.data.sale:
            state.openSec = data.openSec;
            state.closeSec = data.closeSec;
            state.drawId = data.issuse;
            break;
          case serve.user.bet:
            state.bet_score = data[0].score;
            state.balance = data[0].balance;
            break;
          case serve.banner.list:
            if (config.data.type === 1) {
              updateArray(state.banners1, data);
            } else if (config.data.type === 2) {
              updateArray(state.banners2, data);
            }
        }
        return Promise.resolve(data);
      })
    },
    countdown({state}) {
      state.openSec--;
      state.closeSec--;
    }
  }
});

export default store;
