import Vue from 'vue'
import App from './App.vue'
import './utils/rem'
import './vant'
import Axios from './request'
import VueAxios from 'vue-axios'
import {Env} from "./constant";
import VueRouter from 'vue-router'
import routes from './routers';

import store from "./store";
import "./styles/index.less"

if (process.env.NODE_ENV === Env.development) {
  require("./mock.js");
}

Vue.use(VueAxios, Axios);
Vue.use(VueRouter)

const router = new VueRouter({
	mode: 'history',
	routes: routes
})

Vue.config.productionTip = false;
Vue.prototype.$g_temp = Vue.prototype.$g_temp || {};
Vue.prototype.$g_event= Vue.prototype.$g_event || new Vue();

new Vue({
  store,router,
  render: h => h(App),
}).$mount('#app');
