import Axios from 'axios'
//import {Toast} from "vant";
import XEUtils from "xe-utils";
import {CookiesKey} from "./constant";
import {TransData} from "./utils/misc";

const inst = Axios.create({
  baseURL: process.env.VUE_APP_DOMAIN,
  timeout: 1000 * 30
});

inst.interceptors.request.use(value => {
  let token = new TransData()[CookiesKey.token];
  if(!token){
    token = sessionStorage.getItem("token");
  }
  if (token) {
    value.headers["Authorization"] = token;
  }
  value.data = XEUtils.serialize(value.data);
  if (value.method === "get") {
    value.url += "?" + value.data;
  }
  return value;
});

/* inst.interceptors.response.use(value => {
  switch (value.status) {
    case 200:
      console.info(value.data);
      if (value.data.code !== null) {
        switch (value.data.code) {
          case 200:
            return value.data.data;
          default:
            console.log(1)
            Toast.fail(value.data.message);
            //return Promise.reject(new Error(value.data.message));
            return value.data;
        }
      } else {
        return value.data;
      }
    case 401:
      break;

    default:
      return Promise.reject(new Error("网络错误"));
  }
}); */

export default inst
