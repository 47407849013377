
const Index = resolve => require(['./pages/IndexPage'], resolve);
const Bets = resolve => require(['./pages/Bets'], resolve);
const WinnersRecord = resolve => require(['./pages/WinnersRecord'], resolve);
const WinnersMode = resolve => require(['./pages/WinnersMode'], resolve);
const MyCoupon = resolve => require(['./pages/MyCoupon'], resolve);

const routes = [
  { path: '/', component: Index},
  { path: '/bets', component: Bets},
  { path: '/winnersRecord', component: WinnersRecord, name:"WinnersRecord"},
  { path: '/winnersMode', component: WinnersMode},
  { path: '/myCoupon', component: MyCoupon},
]

export default routes;
