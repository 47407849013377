<template>
  <div id="app">
    <div class="container">
      <!-- <IndexPage v-if="ready"></IndexPage> -->
      <transition name="fade" mode="out-in" appear>
        <keep-alive>
          <router-view></router-view>
        </keep-alive>
      </transition>
    </div>
    <!-- 开启 safe-area-inset-bottom 属性 -->
    <van-number-keyboard safe-area-inset-bottom/>
  </div>
</template>

<script>

    //import IndexPage from "./components/IndexPage";
    import {getQueryString} from "./utils/misc";
    import {serve} from "./constant";

    export default {
        name: 'App',
        components: {
            //IndexPage
        },
        data() {
            return {
                ready: true
            }
        },
        created() {
            let partner = getQueryString("partner");
            let sign = getQueryString("sign");
            let userName = getQueryString("userName");

            if(!partner || !sign || !userName){
              partner = sessionStorage.getItem("partner");
              sign = sessionStorage.getItem("sign");
              userName = sessionStorage.getItem("userName");
            }
            console.log(partner);
            console.log(sign);
            console.log(userName);
            if (partner && sign && userName) {
                sessionStorage.setItem("partner",partner);
                sessionStorage.setItem("sign",sign);
                sessionStorage.setItem("userName",userName);
                this.$store.dispatch('serve_req', {
                    url: serve.user.login,
                    method: 'get',
                    data: {partner, sign, userName}
                }).then(()=>{
                    this.$g_event.$emit("login_ok");
                })
            }
        }
    }
</script>

<style lang="less">
  #app {
    font-family: Avenir, Helvetica, Arial, sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    text-align: center;
    color: #2c3e50;
  }
  .van-toast--unclickable *{
    pointer-events: auto!important;
  }
  .headnav{
    height: 104px;
    z-index: 99;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    background-color: #FFF;
    color: #3C3C3C;
    display: flex;
    align-items: center;
    font-size: 32px;
    font-weight: bold;
    border-bottom: 1px solid #f7f7f7;
    .icon{
      width: 40px;
      height: 40px;
      background-image: url(assets/icon/icon-left.png);
      background-size: contain;
      margin-right: 20px;
      margin-left: 20px;
    }
  }
</style>
