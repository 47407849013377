// rem等比适配配置文件

// 设置 rem 函数
function setRem() {
  // 基准大小，注意此值要与 postcss.config.js 文件中的 rootValue保持一致
  const baseSize = 75;
  const designSize = 750;
  const scale = document.documentElement.clientWidth / designSize;
  // 设置页面根节点字体大小
  document.documentElement.style.fontSize = baseSize * Math.min(scale, 1.5) + 'px'
}

// 初始化
setRem();
// 改变窗口大小时重新设置 rem
window.onresize = function () {
  setRem()
};
