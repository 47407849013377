export const Env = {
  development: "development",
  production: "production"
};

// 向后端请求的接口
export const serve = {
  user: {
    login: '/user/login',
    bet: '/user/bet',
    history: '/user/history',
    myCoupon: '/user/ticketList',
  },
  data:{
    sale:'/data/sale',
    list:'/data/list',
    winners:'/data/winners',
  },
  banner:{
    list:'/banner/list'
  },

  api: {
    swipe: '/api/swipe',
    orders: '/api/orders',
    results: '/api/results',
    winners: '/api/winners',
    bet: '/api/bet',
    pay: '/api/pay'
  }
};

export const CookiesKey = {
  token: "token"
};

export const gameType = 5;
export const gameSource = 'h5';
